import { Link } from "gatsby"
import { css } from "@emotion/react"

import {
  style,
  color,
  font,
} from "../utils/styles"

import SEO from "../components/seo"
import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO />
      <div
        css={css`
          ${style.content}
          padding: 80px 0 220px;
          color: ${color.main};
          min-height: calc(100vh - 300px);
        `}
      >
        <h1
          css={css`
            ${font.h1}
          `}
        >
          404 Not Found...
        </h1>
        <p
          css={css`
            margin-top: 16px;
          `}
        >
          お探しのページが見つかりませんでした。
        </p>
        <p
          css={css`
            margin-top: 8px;
          `}
        >
          <Link to="/">トップページ</Link>
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
